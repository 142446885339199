import React from 'react';
import styled from "@emotion/styled";
import { makeStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Container from "@material-ui/core/Container";
import { toRem, breakpoints } from "@utils/mixins"
import { setColors } from '@theme/colors';
// import { Box } from '@material-ui/core';

  export const FooterWrapper = styled.footer`
    text-align: center;
    padding: 10px 0;
    margin-top: auto;

    @media ${breakpoints.largeDesktop} {
      padding: 20px 0;
    }
  `

    export const Box = styled.div`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: auto;
      width: 100%;
      margin-bottom: 0;
      @media ${breakpoints.laptop} {
        width: 100%;
        margin-bottom: toRem(10);
        justify-content: center;
      }

      @media ${breakpoints.largeDesktop} {
      }

      &.social {
        justify-content: center;
      }
    `


      export const Container = styled.div`
        max-width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        flex-direction: column;

        @media ${breakpoints.laptop} {
          flex-direction: row;
          align-items: center;
        }

        @media ${breakpoints.desktop} {
          flex-direction: row;
          align-items: center;
        }

        @media ${breakpoints.largeDesktop} {
        }
      `

    export const GridItem = styled.div`
      display: flex;
      align-items: flex-end;
      justify-content: center;
      text-align: left;
      width: 100%;
      margin: 0 ${toRem(5)};
      margin-bottom: ${toRem(6)};

      @media ${breakpoints.desktop} {
        margin: ${toRem(20)};
        margin-bottom: 0;
        text-align: center;
        width: auto;
        align-items: center;
      }
      @media ${breakpoints.largeDesktop} {
      }

      &.social {
        text-align: center;
      }
    `

  export const Image = styled.img`
    width: ${toRem(24)};
    height: auto;
    margin: 0 5px;

    &.facebook {
      margin: 0 1px;
    }

    @media ${breakpoints.largeDesktop} {
    }
  `

    export const Text = styled.span`
      font-size: ${toRem(10)};
      font-weight: 500;
      color: ${setColors.black.full};
      vertical-align: "middle";
      text-align: left;
      display: inline-block;
      width: auto;

      @media ${breakpoints.largeDesktop} {
        width: calc(100% - 20px);
        font-size: calc(0.2vw + 10px);
        display: block;
      }
    `

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },
    iconContainer: {
      width: toRem(40),
      minWidth: toRem(40),
      textAlign: "center",
      marginRight: toRem(5),
      [theme.breakpoints.down("xs")]: {
        textAlign: "centerleft",
      },
    },
    mailIcon: {
      fontSize: toRem(20),
      verticalAlign: "middle",
      textAlign: "left",
      height: "auto",
      alignSelf: "center",
      [theme.breakpoints.down("xs")]: {
        alignSelf: "flex-start",
      },
    },
    roomIcon: {
      fontSize: toRem(20),
      verticalAlign: "middle",
      textAlign: "left",
      height: "auto",
      alignSelf: "center",
      [theme.breakpoints.down("xs")]: {
        alignSelf: "flex-start",
      },
    },
    ribbonIcon: {
      fontSize: toRem(20),
      display: "inline-block",
      verticalAlign: "middle",
      textAlign: "left",
      alignSelf: "center",
      [theme.breakpoints.down("xs")]: {
        alignSelf: "flex-start",
      },
    },
  }))

const Footer = () => {

  const classes = useStyles()

  return (
    <FooterWrapper>
      <Container>
        {/* <div className={classes.root}> */}
          <GridItem>
            <Box>
              <div className={classes.iconContainer}>
                <img
                  src="images/ribbon-icon.png"
                  width="11"
                  height="auto"
                  alt="Address"
                  className={classes.ribbonIcon}
                />
              </div>
              <Text>MedTech Holdings Limited</Text>
            </Box>
          </GridItem>
          <GridItem>
            <Box>
              <div className={classes.iconContainer}>
                <img
                  src="images/location-icon.png"
                  width="15"
                  height="auto"
                  alt="Adress"
                  className={classes.roomIcon}
                />
              </div>
              <Text>
                REGUS Building, Suite 138, Liberation Station, Esplanade, St
                Helier, Jersey JE2 3AS
              </Text>
            </Box>
          </GridItem>
          <GridItem>
            <Box>
              <div className={classes.iconContainer}>
                <img
                  src="images/email-icon.png"
                  alt="Adress"
                  width="20"
                  height="auto"
                />
              </div>
              <Text>
                <a href="mailto:office@medtechholdings.com">
                  office@medtechholdings.com
                </a>
              </Text>
            </Box>
          </GridItem>
          <GridItem className="social">
            <Box className="social">
              <a
                href="https://www.facebook.com/MedTechHoldings"
                target="_blank" rel="noreferrer"
              >
                <Image
                  src="images/facebook.png"
                  alt="Facebook"
                  className="facebook"
                />
              </a>
              <a href="https://twitter.com/MedTechHoldings" target="_blank" rel="noreferrer">
                <Image
                  src="images/twitter.png"
                  alt="Twitter"
                />
              </a>
              <a
                href="https://www.instagram.com/medtechholdings/"
                target="_blank" rel="noreferrer"
              >
                <Image
                  src="images/instagram.png"
                  alt="Instagram"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/medtech-holdings/"
                target="_blank" rel="noreferrer"
              >
                <Image
                  src="images/linkedin.png"
                  alt="Linkedin"
                />
              </a>
            </Box>
          </GridItem>
        {/* </div> */}
      </Container>
    </FooterWrapper>
  )
}

export default Footer