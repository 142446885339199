import { css } from "@emotion/react"

export const fonts = css`
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
`

export const setFonts = {
  stack: css`
    font-family: "Roboto", sans-serif;
  `,
}