import { css } from "@emotion/react"
import normalize from "emotion-normalize"
import { toRem, setTransition } from "@utils/mixins"
import { setColors } from "./colors"
import { fonts, setFonts } from "./typography"

export const globalStyles = css`
  ${normalize}
  ${fonts}

*,
  *:before,
  *:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  input,
  textarea {
    outline: none;
  }

  button,
  input[type="submit"] {
    outline: none;
    border: none;
  }

  button:hover,
  input[type="submit"] {
    cursor: pointer;
    transform: translateY(-0.25em);
  }

  li,
  a {
    text-decoration: none;
  }

  html {
    font-size: 62.5%;
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
  }

  body {
    ${setFonts.stack};
    font-size: ${toRem(13)};
    color: ${setColors.black.full};
    line-height: 1.3;
  }

  *::-moz-selection {
    color: white;
    background: ${setColors.blue};
  }

  *::selection {
    color: white;
    background: ${setColors.blue};
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  img {
    max-width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
    ${setTransition({ property: "opacity" })};

    &:hover {
      opacity: 0.8;
    }
  }

  button {
    appearance: none;
    display: inline-block;
    border-radius: 0;
    background: none;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
  }

  address {
    font-style: normal;
  }

  p {
    margin-bottom: 1em;
  }

  .show {
    display: block;
  }

  .hidden {
    display: none;
  }
`
