
export const setColors = {
  blue: "#3dbadd",
  grey: {
    lighter: "#f2f2f2",
    medium : "#fafafa",
    darker: "#787878"
  },
  white: "#ffffff",
  black: {
    full: "#000000",
    alpha: "rgba(0,0,0,0.6)"
},
}
