import React from "react"
import { Global } from "@emotion/react"
import { globalStyles } from "@theme/global"
import { Main } from "./Layout.styles"
import Footer from "@components/Footer"

const Layout = ({ children }) => {
  return (
    <>
      <Global styles={globalStyles} />
      <Main>
        {children}
        <Footer />
      </Main>
    </>
  )
}

export default Layout
